import { Button, Chip, Divider, Grid, Heading, IconButton, Input, LoadingBuzz, Select, Table, Tabs, Toggle } from '@hexa-ui/components';

import { Plus } from '@hexa-ui/icons';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormErrorMessage from '../../components/atoms/FormErrorMessage/FormErrorMessage';
import BreadcrumbDefault from '../../components/BreadcrumbDefault';
import ButtonBack from '../../components/ButtonBack';

const CreateWorkflowPage = (): JSX.Element => {
    const navigate = useNavigate();
    const [dataRequests, setDataRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterPipeline, setFilterPipeline] = useState('');
    const [additionalApprovers, setAdditionalApprovers] = useState([]);
    const [additionalApproverEmail, setAdditionalApproverEmail] = useState('');
    const [authParameters, setAuthParameters] = useState([]);
    const [authParameter, setAuthParameter] = useState({
        location: '',
        name: '',
        value: ''
    });

    useEffect(() => {


    }, [])

    const handleOnchangeFilterStatus = (e) => {
        setFilterStatus(e)
        var filteredStatus = []
        debugger
        if (filterPipeline.length > 0) {
            filteredStatus = dataRequests.filter(obj => obj["result"] === e && obj["pipelineId"] == filterPipeline);
        } else {
            filteredStatus = dataRequests.filter(obj => obj["Status"] === e);
        }

        //setSearchFilter(filteredStatus);
    }

    const clearFilters = () => {
        setFilterPipeline('')
        setFilterStatus('')
        //setSearchFilter(dataRequests)
    }

    const handleInputChangeApproverEmail = (event) => {
        const { name, value } = event.target;

        setAdditionalApproverEmail(value)
    };

    const handleAddApprover = () => {
        additionalApprovers.push({ 'approver': additionalApproverEmail })
        setAdditionalApprovers(additionalApprovers)
        setAdditionalApproverEmail('')

    }

    const handleChangeParameter = (event) => {
        const { name, value } = event.target;

        setAuthParameter((prevAuthParameter) => ({
            ...prevAuthParameter,
            [name]: value
        }));
    };
    const handleChangeParameterLocation = (value) => {
        setAuthParameter((prevAuthParameter) => ({
            ...prevAuthParameter,
            ['location']: value
        }));
    };

    const handlerAddParameter = (value) => {
        authParameters.push({ location: authParameter.location, name: authParameter.name, value: authParameter.value });
        var newp = authParameters;
        setAuthParameters(newp);
        //setAuthParameter({ location: '', name: '', value: '' });
    };



    return (
        <Grid.Container
            style={{
                rowGap: '1rem',
                padding: '24px 18px',
            }}
        >


            <Grid.Item lg={12}>
                <BreadcrumbDefault
                    key={1}
                    config={[

                        {
                            title: "Approval Flow",
                            href: "",
                            isCurrentPage: false
                        },
                        {
                            title: "Request Approvals",
                            href: "",
                            isCurrentPage: true
                        }

                    ]}
                />
            </Grid.Item>




            <Grid.Item lg={12} style={{ display: 'flex' }}>
                <ButtonBack link={'/oneask-portal/catalog'} />
                {/* <Header title="Request Approvals"></Header> */}

            </Grid.Item>


            {
                isLoading ? (
                    <Grid.Item xs={12} md={12} sm={12} style={{ flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                            <LoadingBuzz size='xxlarge' />
                        </div>
                    </Grid.Item>
                ) :
                    (
                        <>
                            <Formik
                                initialValues={{}}
                                validationSchema={{}}
                                onSubmit={() => { }}
                                enableReinitialize={true}
                            >
                                {formik => (
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Grid.Container style={{ margin: '0', rowGap: '1rem' }}>

                                            <Grid.Item sm={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Input
                                                    required
                                                    name="name"
                                                    label="Name *"
                                                    placeholder="Enter name for request"
                                                    errorText="Error Text"
                                                    width="100%"
                                                    onBlur={formik.handleChange}
                                                />
                                                <FormErrorMessage name="name" />
                                            </Grid.Item>

                                            <Grid.Item sm={8} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Input
                                                    required
                                                    name="description"
                                                    label="Description *"
                                                    placeholder="Enter age for request"
                                                    errorText="Error Text"
                                                    width="100%"
                                                    onBlur={formik.handleChange}
                                                />
                                                <FormErrorMessage name="description" />
                                            </Grid.Item>

                                            <Grid.Item sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                <Input
                                                    required
                                                    name="minimum_approval"
                                                    label="Minimum Approval *"
                                                    placeholder="Enter minimum for approval"
                                                    errorText="Error Text"
                                                    width="100%"
                                                    onBlur={formik.handleChange}
                                                />
                                                <FormErrorMessage name="minimum_approval" />
                                            </Grid.Item>
                                            <Grid.Item sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                <Input
                                                    required
                                                    name="responsible_team"
                                                    label="Responsible Team *"
                                                    placeholder="Enter responsible for approval"
                                                    errorText="Error Text"
                                                    width="100%"
                                                    onBlur={formik.handleChange}
                                                />
                                                <FormErrorMessage name="responsible_team" />
                                            </Grid.Item>

                                            <Grid.Item sm={6} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                <Input
                                                    required
                                                    name="webhook"
                                                    label="URL Webhook *"
                                                    placeholder="Enter responsible for approval"
                                                    errorText="Error Text"
                                                    width="100%"
                                                    onBlur={formik.handleChange}
                                                />
                                                <FormErrorMessage name="responsible_team" />
                                            </Grid.Item>
                                            <Grid.Item sm={3} style={{ display: 'flex', marginTop: '10px' }}>

                                                <Toggle style={{ marginRight: '5px' }} />
                                                <label htmlFor="role" className='label'><b>Send Slack notification</b></label>
                                            </Grid.Item>
                                            <Grid.Item sm={3} style={{ display: 'flex', marginTop: '10px' }}>

                                                <Toggle style={{ marginRight: '5px' }} />
                                                <label htmlFor="role" className='label'><b>Send E-mail notification</b></label>
                                            </Grid.Item>

                                            <Tabs.Root defaultValue='tab1'>
                                                <Tabs.List>
                                                    <Tabs.Trigger value='tab1'>
                                                        Additional Approvers
                                                    </Tabs.Trigger>
                                                    <Tabs.Trigger value='tab2' >
                                                        Auth Configurations
                                                    </Tabs.Trigger>
                                                </Tabs.List>
                                                <Tabs.Content value='tab1'>

                                                    <Grid.Container  >

                                                        <Grid.Item sm={6} style={{ display: 'flex' }}>
                                                            <Input
                                                                required
                                                                name="approver_email"
                                                                label="Approver E-mail"
                                                                placeholder="Enter responsible for approval"
                                                                errorText="Error Text"
                                                                width="100%"
                                                                onChange={handleInputChangeApproverEmail}
                                                                value={additionalApproverEmail}
                                                            //onBlur={formik.handleChange}
                                                            />


                                                        </Grid.Item>
                                                        <Grid.Item md={6} lg={6} style={{ marginTop: 10, alignItems: 'center' }}>
                                                            <IconButton icon={Plus} onClick={handleAddApprover}></IconButton>
                                                            {/* <Button icon={Plus} leading size="large" variant="primary" onClick={() => clearFilters()}>
                                                Clear Filters
                                            </Button> */}
                                                        </Grid.Item>
                                                        <Grid.Item lg={12} style={{ marginTop: '10px', padding: 10 }}>
                                                            {/* <ul> */}
                                                            {additionalApprovers.map(({ approver }) => (
                                                                <Chip.Root style={{ marginLeft: 10 }}>
                                                                    <Chip.Label onClick={() => { }}>
                                                                        {approver}
                                                                    </Chip.Label>
                                                                    <Chip.DeleteIcon onDelete={() => { }}></Chip.DeleteIcon>
                                                                </Chip.Root>
                                                                // <ListItem>
                                                                //     <Paragraph>{approver}</Paragraph> <IconButton icon={Edit2} variant="inherit" />

                                                                // </ListItem>

                                                            ))}

                                                            {/* </ul> */}


                                                            { }
                                                        </Grid.Item>
                                                    </Grid.Container>





                                                </Tabs.Content>
                                                <Tabs.Content value='tab2'>
                                                    <Grid.Container type='fluid'>
                                                        {/* <Grid.Item lg={12} style={{ justifyContent: 'end' }}>
                                                            <Button icon={Plus} leading size="large" variant="primary" onClick={() => clearFilters()}>
                                                                New
                                                            </Button>
                                                        </Grid.Item> */}
                                                        <Grid.Item lg={12} style={{ marginTop: '10px' }}>
                                                            <Select.Root value={filterStatus} placeholder="Result" onChange={(e) => handleOnchangeFilterStatus(e)} >
                                                                <Select.Option disabled value={""}>Authentication type</Select.Option>
                                                                <Select.Option value={"oauth2"}>OAuth2</Select.Option>
                                                                <Select.Option value={"custom-headers"}>Custom Header</Select.Option>
                                                            </Select.Root>
                                                        </Grid.Item>
                                                        <Grid.Item lg={12} style={{ marginTop: '10px' }}>
                                                            <Heading size='H4'>
                                                                Parameters
                                                            </Heading>

                                                        </Grid.Item>
                                                        <Divider />
                                                        <Grid.Item md={12} style={{ marginBottom: 20 }}>

                                                        </Grid.Item>
                                                        <Grid.Item md={2} style={{ marginTop: 0 }}>
                                                            <div style={{ width: '100%' }}>
                                                                <label className='label' style={{ marginTop: 1 }}>Parameter Location</label>
                                                                <div style={{ marginTop: 0 }}>
                                                                    <Select.Root size='large' name='location' placeholder="" onChange={(v) => handleChangeParameterLocation(v)} >
                                                                        <Select.Option disabled value={""}>Parameter Location</Select.Option>
                                                                        <Select.Option value={"body"}>Body</Select.Option>
                                                                        <Select.Option value={"header"}>Header</Select.Option>
                                                                    </Select.Root>
                                                                </div>

                                                            </div>

                                                        </Grid.Item>
                                                        <Grid.Item lg={5} style={{ marginTop: 1 }}>
                                                            <Input
                                                                required
                                                                label='Parameter Name'
                                                                name="name"
                                                                placeholder="e.g., client_id"
                                                                errorText="Error Text"
                                                                width="100%"
                                                                value={authParameter.name}
                                                                onChange={(v) => handleChangeParameter(v)}

                                                            //onBlur={formik.handleChange}
                                                            />
                                                        </Grid.Item>

                                                        <Grid.Item lg={4} style={{}}>
                                                            <Input
                                                                required
                                                                name="value"
                                                                label='Parameter Value'
                                                                placeholder="e.g., a3bf406b-722a-4069-8dfe-9b7c5a5c8783"
                                                                errorText="Error Text"
                                                                width="100%"
                                                                size='large'
                                                                value={authParameter.value}
                                                                onChange={(v) => handleChangeParameter(v)}
                                                            //onBlur={formik.handleChange}
                                                            />

                                                        </Grid.Item>
                                                        <Grid.Item lg={1} style={{ marginTop: '10px', alignItems: 'center' }}>
                                                            <IconButton icon={Plus} onClick={handlerAddParameter} />

                                                        </Grid.Item>


                                                        <Grid.Item md={12} style={{ marginTop: 20 }}>
                                                            <Table

                                                                key={1}
                                                                emptyMessage="No parameter added"
                                                                columns={[
                                                                    {
                                                                        Header: 'Location',
                                                                        accessor: 'location',
                                                                        disableSortBy: false,
                                                                        customRender: (value, row) => {
                                                                            return row.location

                                                                        },
                                                                        style: {
                                                                            width: '40%',


                                                                        }
                                                                    },
                                                                    {
                                                                        Header: 'Name',
                                                                        accessor: 'name',
                                                                        disableSortBy: false,
                                                                        style: {
                                                                            width: '20%'
                                                                        },
                                                                        customRender: (value, row) => {
                                                                            return row.name
                                                                        },
                                                                    },
                                                                    {
                                                                        Header: 'Value',
                                                                        accessor: 'value',
                                                                        disableSortBy: false,
                                                                        style: {
                                                                            width: '20%'
                                                                        },
                                                                        customRender: (value, row) => {
                                                                            return row.value;
                                                                        },
                                                                    }

                                                                ]}
                                                                data={authParameters}
                                                                pagination={{
                                                                    pageSize: 10,
                                                                    pageSizeOptions: [
                                                                        5,
                                                                        10,
                                                                        20
                                                                    ],
                                                                    showPageSizeSelector: true
                                                                }}
                                                                initialSortBy={[

                                                                ]}
                                                            />

                                                        </Grid.Item>


                                                    </Grid.Container>
                                                </Tabs.Content>
                                            </Tabs.Root>

                                            <Grid.Item sm={12} style={{ justifyContent: 'center' }}>
                                                <Button
                                                    // isLoading={onLoadingSubmit}
                                                    // disabled={onLoadingSubmit}
                                                    elevated
                                                    size="medium"
                                                    variant="primary"
                                                    type='submit'
                                                >
                                                    Request Access
                                                </Button>
                                            </Grid.Item>
                                        </Grid.Container>
                                    </Form>
                                )}
                            </Formik>

                        </>


                    )
            }


        </Grid.Container >
    );
};

export default CreateWorkflowPage;