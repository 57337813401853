import httpClient from "./client";

export async function runCreateAccess(data): Promise<void> {
    await httpClient.post('/approval/request', data);
}

export async function listRequests(approverEmail): Promise<any> {
    const { data } = await httpClient.get(`/approval/requests?approverEmail=${approverEmail}`);
    return data;
}

export async function listWorkflow(): Promise<any> {
    const { data } = await httpClient.get('/approval/workflow/list');
    return data;
}

export async function getRequestDetails(requestId, approverEmail): Promise<any> {
    const { data } = await httpClient.get(`/approval/request/${requestId}/details?approverEmail=${approverEmail}`);
    return data;
}

export async function approveRequest(body): Promise<any> {
    const response = await httpClient.post(`approval/request/approve`, body);
    return response;
}

export async function getWorkflowDescribe(workflowId, runId): Promise<any> {
    const { data } = await httpClient.get(`/workflow/${workflowId}/run/describe?runId=${runId}`);
    return data;
}

export async function getWorkflowRuns(): Promise<any> {
    const { data } = await httpClient.get(`/workflow/list?workflowType=DummyWorkflow`);
    return data;
}